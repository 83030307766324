import moment from 'moment';
import { DateFormat } from '../constants/dateFormats';

export interface IDateService {
    format(date: Date | string | number, format: DateFormat): string;
    parse(dateString: string, format: DateFormat): Date;
    isValid(dateString: string, format: DateFormat): boolean;
}

export class DateService implements IDateService {
    format(date: Date | string | number, format: DateFormat): string {
        return moment(date).format(format);
    }

    parse(dateString: string, format: DateFormat): Date {
        const parsed = moment(dateString, format);
        if (!parsed.isValid()) {
            throw new Error('Invalid date string');
        }
        return parsed.toDate();
    }

    isValid(dateString: string, format: DateFormat): boolean {
        return moment(dateString, format, true).isValid();
    }
}

// Singleton instance
export const dateService = new DateService();

export const today = new Date();