import React, { useState, useEffect } from "react";
import {
  OobAuthorizationApiPayloadType,
  oobCardsPayload,
} from "../../api/types";
import { getOobTransactionApi } from "../../api/digiBankInstance";
import { banks, oobTransactionStatus } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { setAddToOrderState } from "../../features/order/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectBasket } from "../../features/basket/basketSlice";
import { useToast } from "../toast";
import { dateService } from "../../services/dateService";
import { DateFormats } from "../../constants/dateFormats";
import { formatCurrency } from "../../utils";
import Logo from "../Logo/Logo";

interface PaymentApprovalModalProps {
  paymentDetails: OobAuthorizationApiPayloadType | null;
  onClose: () => void;
  onApprove: () => void;
  timeoutDuration: number;
  isOpen: boolean;
}



const PaymentApprovalModal: React.FC<PaymentApprovalModalProps> = ({
  paymentDetails,
  onClose,
  onApprove,
  timeoutDuration,
  isOpen,
}) => {
  const navigator = useNavigate();
  const [timeLeft, setTimeLeft] = useState(timeoutDuration);
  const [disableAlreadyApprovedButton, setDisableAlreadyApprovedButton] =
    useState(true);

  const basket = useSelector(selectBasket);
  const [authStatus, setAuthStatus] = useState(oobTransactionStatus.INITIATED);
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const today = new Date();

  useEffect(() => {
    if (paymentDetails && isOpen) {
      getOobTransactionApiRequest(paymentDetails);
    }
  }, [paymentDetails, isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const getOobTransactionApiRequest = async (paymentDetails: any) => {
    try {
      let payload: oobCardsPayload = {
        cardNo: paymentDetails?.card.cardNo,
        account: {
          accountNumber: paymentDetails?.card.accountId,
          accountType: "SAVINGS",
          id: paymentDetails.card.accountId,
        },
        customer: {
          id: paymentDetails.customer.customerId,
          firstName: paymentDetails.customer.firstName,
          lastName: paymentDetails.customer.lastName,
          email: paymentDetails.customer.email,
        },
      };

      let response = await getOobTransactionApi(
        payload,
        paymentDetails.transaction.authenticationId
      );

      // setCompletePayment(true);
      // setAuthStatus(oobTransactionStatus.AUTHORIZED);
      setDisableAlreadyApprovedButton(false);
      if (
        response.transactionAuthorizeStatus === oobTransactionStatus.INITIATED
      ) {
        if (!disableAlreadyApprovedButton) {
          addToast({
            message: "Authorization initiated",
            type: "info",
            duration: 3000,
          });
        }
        setDisableAlreadyApprovedButton(false);
      }

      if (
        response.transactionAuthorizeStatus === oobTransactionStatus.AUTHORIZED
      ) {
        setAuthStatus(oobTransactionStatus.AUTHORIZED);
      }

      if (
        response.transactionAuthorizeStatus === oobTransactionStatus.DECLINED
      ) {
        setAuthStatus(oobTransactionStatus.DECLINED);
      }
      if (
        response.transactionAuthorizeStatus === oobTransactionStatus.TIMEDOUT
      ) {
        setAuthStatus(oobTransactionStatus.TIMEDOUT);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (timeLeft > 0 && isOpen) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      // onClose();
    }
  }, [timeLeft, onClose]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCompleteBooking = () => {
    dispatch(setAddToOrderState({ item: basket }));
    navigator("/order");
  };
  const progressPercentage = (timeLeft / timeoutDuration) * 100;
  if (!isOpen) {
    return null;
  }
  
  return (
    <div className="mt-16 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 sm:p-6 md:p-8">
      <div className="bg-white shadow-xl w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-[60%] rounded-xl overflow-hidden">
      <div className="flex justify-between items-center bg-grey p-4">
       {paymentDetails?.card.bankLogo && <Logo domain={paymentDetails?.card.bankLogo} />}
       
       {!paymentDetails?.card.bankLogo &&<img src={paymentDetails?.card.bank === banks.federalBank ? "images/federalBankLogo.png": "images/Logo.svg"} className="w-24" alt="brand" />}
          {paymentDetails?.card.paymentScheme === "MasterCard" && (
            <div className="flex flex-row gap-1 items-center">
              <img
                src="https://www.mastercard.co.in/content/dam/public/mastercardcom/in/en/logos/mc-logo-52.svg"
                alt="Mastercard logo"
                // className="sm:h-8 md:h-10"
              />
              <p className="font-semibold">Mastercard</p>
            </div>
          )}
          {paymentDetails?.card.paymentScheme === "Visa" && (
            <div className="flex flex-row gap-1 items-center">
              <img
                src="images/visa.png"
                alt="Mastercard logo"
                className="xs:size-2 md:size-4"
              />
              <p className="font-semibold ">Visa</p>
            </div>
          )}
        </div>
        <div className="flex flex-col p-4 items-center gap-10 bg-bg-pattern">
          {authStatus === oobTransactionStatus.AUTHORIZED && (
            <img
              src="images/accept.png"
              alt="success"
              className="sm:h-10 md:h-40 animate-roll-in-blurred-left"
            />
          )}
          {(authStatus === oobTransactionStatus.DECLINED ||
            authStatus === oobTransactionStatus.TIMEDOUT) && (
            <img
              src="images/failure.svg"
              alt="declined"
              className="sm:h-10 md:h-40"
            />
          )}{" "}
           <p className="font-bold m-0">
                This approval will timeout in 
              </p>
          {authStatus === oobTransactionStatus.INITIATED && (
            <div className="relative">
              <svg
                className="w-28 h-28 sm:w-40 sm:h-40 md:w-28 md:h-28 lg:w-56 lg:h-56 xl:w-34 xl:h-34 mx-auto"
                viewBox="0 0 100 100"
              >
                <circle
                  className="text-gray-200 stroke-current"
                  strokeWidth="4"
                  cx="50"
                  cy="50"
                  r="40"
                  fill="transparent"
                ></circle>

                <circle
                  className="text-red-500 progress-ring stroke-current"
                  strokeWidth="4"
                  strokeLinecap="round"
                  cx="50"
                  cy="50"
                  r="40"
                  fill="transparent"
                  strokeDasharray="251.2"
                  strokeDashoffset={251.2 - (251.2 * progressPercentage) / 100}
                  transform="rotate(-90 50 50)"
                ></circle>
              </svg>
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-xl sm:text-2xl md:text-3xl font-semibold">
                  {formatTime(timeLeft)}
                </span>
              </div>
            </div>
          )}
          <div className="">
          <p className="text-center text-base font-semibold xs:text-xs sm:text-lg 2xl:text-xl">
            {authStatus === oobTransactionStatus.INITIATED
              // ? `Please use your ${paymentDetails?.card.bank === banks.federalBank?"FedMob":"DigiBank"}mobile app to approve the transaction.`
              ? `Please use your ${paymentDetails?.card.bankAppName ?? "DigiBank"} App to approve the transaction.`
              : authStatus === oobTransactionStatus.AUTHORIZED
              ? "Payment authorized successfully"
              : authStatus === oobTransactionStatus.DECLINED
              ? "Authorization Declined"
              : authStatus === oobTransactionStatus.TIMEDOUT
              ? "Authorization Time out"
              : ""}

              
          </p>
         {authStatus === oobTransactionStatus.INITIATED && 
         <p className="text-center text-base font-semibold sm:text-lg xs:text-xs  2xl:text-xl mb-1 "> 
         {/* {`You will be notified on your ${paymentDetails?.card.bank === banks.federalBank?"FedMob":"DigiBank"} App.`} */}
         {`Once you have approved the transaction on ${paymentDetails?.card.bankAppName ?? "DigiBank"} App, please click on the link below.`}
          </p>}
          </div>

          {authStatus === oobTransactionStatus.INITIATED && (
            <div
              className={`flex w-full items-center justify-center text-xs sm:text-sm md:text-base text-gray-500`}
            >
                <p className="text-sm font-bold text-blue-500 underline hover:cursor-pointer"  
                onClick={() =>
                  paymentDetails && getOobTransactionApiRequest(paymentDetails)
                }
                >
                   {`I've already approved this in the ${paymentDetails?.card.bankAppName ?? "DigiBank"} app `} 
                   </p>
            </div>
          )}

          
          <div className="bg-white shadow-md md:w-8/12 xs:w-full rounded-lg p-3 sm:p-4">
            <div className="flex items-center">
              <img
                src="images/amazon.png"
                alt={paymentDetails?.merchant.name}
                className="w-8 h-8 sm:w-10 sm:h-10 rounded-full mr-3"
              />
              <div className="flex-grow">
                <p className="font-semibold text-sm sm:text-base md:text-lg">
                  {paymentDetails?.merchant.name}
                </p>
                <p className="text-xs sm:text-sm text-gray-500">
                  {dateService.format(today, DateFormats.basic.standard)}
                </p>
              </div>
              <div>
                <p className="font-bold text-sm sm:text-base md:text-lg">
                  {paymentDetails &&
                    formatCurrency(
                      paymentDetails.transaction.amount,
                      paymentDetails.transaction.currency
                    )}
                </p>
              </div>
            </div>
          </div>
        
          {authStatus === oobTransactionStatus.INITIATED && (
            <div
              className={`flex w-full items-center justify-center text-xs sm:text-sm md:text-base text-gray-500`}
            >
             

              <button
                onClick={() =>
                 onClose()
                }
                disabled={disableAlreadyApprovedButton}
                className={` ${
                  !disableAlreadyApprovedButton
                    ? "bg-yellow-500 hover:cursor-pointer"
                    : "bg-gray-400"
                } text-white py-3 px-6 rounded-full shadow-lg transition-all`}
              >
                <p className="text-sm font-bold"> {`Cancel`} </p>
              </button>
            </div>
          )}
          {authStatus === oobTransactionStatus.AUTHORIZED && (
            <button
              onClick={handleCompleteBooking}
              disabled={disableAlreadyApprovedButton}
              className={`xs:w-10/12 md:w-4/12 ${
                !disableAlreadyApprovedButton
                  ? "bg-yellow-500 hover:cursor-pointer"
                  : "bg-gray-400"
              } text-white py-3 px-6 rounded-full shadow-lg `}
            >
              <p className="text-sm font-bold"> Complete Shopping </p>
            </button>
          )}
          {(authStatus === oobTransactionStatus.DECLINED ||
            authStatus === oobTransactionStatus.TIMEDOUT) && (
            <button
              onClick={() => {
                setAuthStatus(oobTransactionStatus.INITIATED);
                setTimeLeft(30);
                onClose();
              }}
              disabled={disableAlreadyApprovedButton}
              className={`xs:w-10/12 md:w-4/12 ${
                !disableAlreadyApprovedButton
                  ? "bg-yellow-500 hover:cursor-pointer"
                  : "bg-gray-400"
              } text-white py-3 px-6 rounded-full shadow-lg `}
            >
              <p className="text-sm font-bold"> Continue Shopping </p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentApprovalModal;
