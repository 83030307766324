import { Radio } from "@material-ui/core";
import React from "react";
import { cardItemType } from "../types/card";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

interface CardItemProps {
  item: cardItemType;
  selectItem: (item: cardItemType) => void;
  selected: boolean;
}

const CardItem: React.FC<CardItemProps> = (props) => {
  const { item, selected, selectItem } = props;
  const { cardNo, customer, bank } = item;
  console.log("selected", selected);
  return (
    <div
      className={`flex w-full border border-white ${
        selected && "bg-orange_20 border border-orange_50"
      } rounded-[8px] p-4 my-3`}
      onClick={() => selectItem(item)}
    >
      <div className="xs:w-1/12 md:w-2/12 flex justify-start items-start">
        <Radio
          style={{ margin: 0, padding: 0 }}
          value={cardNo}
          checked={selected}
        />
      </div>
      <div className="md:w-8/12 xs:w-10/12 pl-2">
        <div className="flex xs:flex-col md:flex-row xs:gap-0 md:gap-3 md:text-sm xs:text-xs">
          <p className="font-bold ">{`${customer.firstName} ${customer.lastName}`}</p>
          <p> ending in {cardNo.slice(15, 20)}</p>
        </div>
        <div className="flex md:w-3/12 xs:w-5/12  justify-between items-center px-2 p-1 rounded-full border border-grey my-2">
          <p className="text-sm sm:hide lg:block">Pay in full</p>
          <ArrowForwardIosIcon style={{ width: 10, height: 10 }} />
        </div>
        <p className="text-xs">CVV not needed</p>
      </div>
      <div className="w-2/12">
        <p className="text-sm ">{bank}</p>
      </div>
    </div>
  );
};

export default CardItem;
