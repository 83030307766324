import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductType } from "../../types";

// initial State
// const [order, setOrder] = useState([]);

const initialState = {
  order: [],
};

// Prepare the data layer
export const orderSlice = createSlice({
  name: "order",
  initialState,

  reducers: {
    setAddToOrderState: (
      state: { order: ProductType[] },
      action: PayloadAction<{
        item: ProductType[];
      }>
    ) => {
      // first solution(Working)
      // Create a new copy of state.order array, with action.payload appended to the end
      // state.order = state.order.concat(action.payload.item);

      // second solution(Working)
      state.order = [...state.order, ...action.payload.item];
    },

    setRemoveFromOrderState: (
      state: { order: ProductType[] },
      action: PayloadAction<{
        id: number | string;
      }>
    ) => {
      // Construct a new result array immutably and return it
      state.order = state.order.filter(
        (item: { id: number | string }) => item.id !== action.payload.id
      );
    },
  },
});

export const { setAddToOrderState, setRemoveFromOrderState } =
  orderSlice.actions;

// Pull Information from data layer
export const selectOrder = (state: { order: { order: ProductType[] } }) =>
  state.order.order;

export default orderSlice.reducer;
