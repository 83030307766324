import { Toast, ToastPosition } from "./types";
import { ToastItem } from "./ToastItem";
import { POSITION_STYLES } from "./constants";

interface ToastContainerProps {
  position: ToastPosition;
  toasts: Toast[];
  onRemove: (id: string) => void;
}

export const ToastContainer = ({
  position,
  toasts,
  onRemove,
}: ToastContainerProps) => (
  <div
    className={`fixed z-[1000] flex flex-col gap-2 ${POSITION_STYLES[position]}`}
    role="region"
    aria-label="Notifications"
  >
    {toasts.map((toast) => (
      <ToastItem
        key={toast.id}
        toast={toast}
        onClose={() => onRemove(toast.id)}
      />
    ))}
  </div>
);
