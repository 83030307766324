export const DateFormats = {
    basic: {
        standard: 'DD/MM/YYYY',
        short: 'D/M/YY',
        iso: 'YYYY-MM-DD',
        locale: 'L'
    },
    time: {
        time24: 'HH:mm',
        time12: 'hh:mm A',
        timeWithSeconds: 'HH:mm:ss'
    },
    dateTime: {
        standard: 'DD/MM/YYYY HH:mm',
        withSeconds: 'DD/MM/YYYY HH:mm:ss',
        iso: 'YYYY-MM-DDTHH:mm'
    },
    extended: {
        full: 'dddd, MMMM Do YYYY',
        withTime: 'dddd, MMMM Do YYYY, h:mm A',
        monthYear: 'MMMM YYYY'
    }
} as const;

// Correct type definitions
export type DateFormat = 
    | typeof DateFormats.basic[keyof typeof DateFormats.basic]
    | typeof DateFormats.time[keyof typeof DateFormats.time]
    | typeof DateFormats.dateTime[keyof typeof DateFormats.dateTime]
    | typeof DateFormats.extended[keyof typeof DateFormats.extended];

// Individual category types
export type BasicDateFormat = typeof DateFormats.basic[keyof typeof DateFormats.basic];
export type TimeFormat = typeof DateFormats.time[keyof typeof DateFormats.time];
export type DateTimeFormat = typeof DateFormats.dateTime[keyof typeof DateFormats.dateTime];
export type ExtendedFormat = typeof DateFormats.extended[keyof typeof DateFormats.extended];