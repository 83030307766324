import PaymentDetails from "../components/PaymentDetails/PaymentDetails";
import LayoutHigherOrderComponent from "../layouts/index";

function PaymentDetailsScreen() {
  return (
    <LayoutHigherOrderComponent>
      <PaymentDetails />
    </LayoutHigherOrderComponent>
  );
}

export default PaymentDetailsScreen;
