import ApiInterceptor from "./ApiInterceptor";
import { acsRequests } from "./requests";
import { getBaseUrl } from "./utils";

const digibankInstance = new ApiInterceptor(getBaseUrl());

export const initiateOobAuthorizationApi = async (payload: object) => {
  try {
    const response: any = await digibankInstance.post(
      acsRequests.initiateOobAuthorization,
      payload
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getOobTransactionApi = async (
  payload: object,
  authenticationId: string
) => {
  try {
    const response: any = await digibankInstance.get(
      acsRequests.getOobTransaction(authenticationId)
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateAuthorizationResultApi = async (
  payload: object,
  authenticationId: string
) => {
  try {
    const response: any = await digibankInstance.post(
      acsRequests.updateAuthorizationResult(authenticationId),
      payload
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getUserCardsApi = async (
 userId: string,
) => {
  try {
    const response: any = await digibankInstance.get(
      acsRequests.getUserCards(userId)
    );
    return response;
  } catch (error) {
    throw error;
  }
};
