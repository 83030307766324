import React from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import SignUpScreen from "./screens/SignUpScreen";
import OrderScreen from "./screens/OrderScreen";
import CheckOutScreen from "./screens/CheckOutScreen";
import PaymentScreen from "./screens/PaymentScreen";
import Loading from "./components/Loading";
import { auth } from "./config/firebase";
import PaymentDetailsScreen from "./screens/PaymentDetailsScreen";
import { ToastProvider } from "./components/toast";

function App() {
  const [user, loading] = useAuthState(auth);
  console.log(user, loading);

  if (loading) {
    return <Loading />;
  }

  return (
    <AppWrapper>
      <ToastProvider config={{ position: "top-right", defaultDuration: 3000 }}>
        {user ? (
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/register" element={<SignUpScreen />} />
            <Route path="/payment" element={<PaymentScreen />} />
            <Route path="/order" element={<OrderScreen />} />
            <Route path="/checkout" element={<CheckOutScreen />} />
            <Route path="/paymentDetails" element={<PaymentDetailsScreen />} />
          </Routes>
        ) : (
          <LoginScreen />
        )}
      </ToastProvider>
    </AppWrapper>
  );
}

export default App;

const AppWrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
`;
