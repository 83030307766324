import React, { useState, useRef, useCallback } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface OTPInputProps {
  onChangeOtp: (otp: string) => void;
  invokeChangeOnEnd?: boolean;
}
const OTPInput: React.FC<OTPInputProps> = ({
  onChangeOtp,
  invokeChangeOnEnd,
}) => {
  const [otp, setOTP] = useState<string[]>(Array(6).fill(""));
  const [isVisible, setIsVisible] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = useCallback(
    (index: number, value: string) => {
      if (isNaN(Number(value))) return;
      const newOTP = [...otp];
      newOTP[index] = value.slice(-1); // Ensure only one character is stored
      setOTP(newOTP);
      if (!invokeChangeOnEnd) {
        onChangeOtp(newOTP.join(""));
      }
      if (invokeChangeOnEnd && index === 5) {
        onChangeOtp(newOTP.join(""));
      }
      if (value !== "" && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    },
    [otp] // eslint-disable-line react-hooks/exhaustive-deps
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyDown = useCallback(
    (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Backspace" && index > 0 && otp[index] === "") {
        inputRefs.current[index - 1]?.focus();
      }
    },
    [otp]
  );

  const toggleVisibility = useCallback(() => {
    setIsVisible((prev) => !prev);
  }, []);

  return (
    <div className="flex flex-col items-center space-y-6 p-8 ">
      <h2 className="text-2xl font-semibold text-gray-700">Enter OTP</h2>
      <div className="flex space-x-4 ">
        {otp.map((digit, index) => (
          <OTPDigitInput
            key={index}
            value={digit}
            onChange={(value) => handleChange(index, value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            isVisible={isVisible}
            ref={(el) => (inputRefs.current[index] = el)}
          />
        ))}
        <VisibilityToggle
          isVisible={isVisible}
          toggleVisibility={toggleVisibility}
        />
      </div>
    </div>
  );
};

interface OTPDigitInputProps {
  value: string;
  onChange: (value: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isVisible: boolean;
}

const OTPDigitInput = React.forwardRef<HTMLInputElement, OTPDigitInputProps>(
  ({ value, onChange, onKeyDown, isVisible }, ref) => (
    <div className="border border-gray rounded-lg flex justify-center items-center">
      <input
        type={isVisible ? "text" : "password"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        maxLength={1}
        className="w-8 h-10 hover:cursor-pointer text-center text-2xl font-bold text-gray-700 border !border-black "
        ref={ref}
      />
    </div>
  )
);

interface VisibilityToggleProps {
  isVisible: boolean;
  toggleVisibility: () => void;
}

const VisibilityToggle: React.FC<VisibilityToggleProps> = ({
  isVisible,
  toggleVisibility,
}) => (
  <button
    onClick={toggleVisibility}
    className="p-3  rounded-lg hover:bg-gray-100 duration-200 ease-in-out"
    aria-label={isVisible ? "Hide OTP" : "Show OTP"}
  >
    {isVisible ? (
      <VisibilityOff className="text-gray-600" />
    ) : (
      <Visibility className="text-gray-600" />
    )}
  </button>
);

export default OTPInput;
