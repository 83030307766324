import { ProductType } from '../types'

const items: ProductType[] = [
	{
		id: 56,
		title: 'Samsung Galaxy Watch 4',
		stock: 'in stock - order soon.',
		image: 'item02.jpg',
		price: 220.99,
		description: 'A powerful experience enhanced with Galaxy favorites.',
		category: 'Samsung Watches',
	},
	{
		id: 57,
		title: 'Amazon Echo & Alexa Devices· Smart speakers',
		stock: 'In stock on May 8, 2021',
		image: 'item0.jpg',
		price: 480.99,
		description:
			'All the benefits of Alexa, now with noticeably improved sound and a new look. Enjoy premium speakers with equaliser controls, and easily set up .',
		category: 'Smart speakers',
	},
	{
		id: 58,
		title: 'The Lean Startup Book by Eric Ries',
		stock: 'Only 5 left in stock - order soon.',
		image: 'item01.jpg',
		price: 280.99,
		description: 'his proposed lean startup strategy for startup companies.',
		category: 'Books',
	},
	{
		id: 587,
		title: 'Shop T shirts men - Amazon Singapore',
		stock: 'In stock on May 8, 2021',
		image: 'item-10.jpeg',
		price: 680.99,
		description:
			'Mens Callahan Auto T Shirt Funny Shirts Cool Humor Graphic Saying Sarcasm Tee.',
		category: "Men's T-Shirts",
	},
	{
		id: 587,
		title: 'Shop T shirts men - Amazon Singapore',
		stock: 'In stock on May 8, 2023',
		image: 'item-11.jpeg',
		price: 680.99,
		description:
			'Mens Callahan Auto T Shirt Funny Shirts Cool Humor Graphic Saying Sarcasm Tee.',
		category: "Men's T-Shirts",
	},
	{
		id: 587,
		title: 'Shop T shirts men - Amazon Singapore',
		stock: 'In stock on May 8, 2023',
		image: 'item-12.jpeg',
		price: 680.99,
		description:
			'Calcutta Men’s Original Logo Short Sleeve T-Shirt – Soft Performance Apparel .',
		category: 'Shoes - Men - Adidas',
	},
	{
		id: 587,
		title: 'Womens Casual Shirt - Amazon Singapore',
		stock: 'In stock on May 8, 2023',
		image: 'item18.jpeg',
		price: 680.99,
		description:
			'Lrady Womens Casual Shirt Dress High Low Irregular Hem Ruched Blouse Asymmetrical Peplum Long Tunic Tops.',
		category: 'Women Shirt Dresses',
	},
	{
		id: 587,
		title: 'Womens Casual Shirt - Amazon Singapore',
		stock: 'In stock on May 8, 2023',
		image: 'item19.jpeg',
		price: 680.99,
		description:
			'Esabel.C Womens Button Down Shirts Long Sleeve Regular Fit Cotton Stretch Work Blouse.',
		category: 'Women Shirt Dresses',
	},
	{
		id: 587,
		title: 'Shop Women Shoes - Amazon Singapore',
		stock: 'In stock on May 8, 2023',
		image: 'item20.jpeg',
		price: 680.99,
		description:
			'Sunnystep walking shoes are designed to minimize stress to the feet and the body. The shoes come with structured insoles and are designed for long walking and standing. ',
		category: 'Women Shoes',
	},
	{
		id: 59,
		title: 'Lenovo Flex 5 14 2-in-1 Laptop, 14.0 FHD (1920 x 1080) ',
		stock: 'Only 3 left in stock - order soon.',
		image: 'item-0.jpg',
		price: 359.99,
		description:
			'Available at a lower price from other sellers that may not offer free Prime shipping.',
		category: 'Personal Computers',
	},
	{
		id: 60,
		title: 'Apple iPad Pro 12.9 4th Gen (2020) A2229',
		stock: ' Only 1 left in stock - order soon.',
		image: 'item-1.jpg',
		price: 769.0,
		description:
			'The 11-inch Liquid Retina display delivers an immersive viewing experience. The 12.9-inch Liquid Retina XDR display offers an even more expansive view and a stunning HDR experience.',
		category: 'electronics',
	},
	{
		id: 61,
		title: 'Beats by Dre - BeatsX Wireless Earphones ',
		stock: 'In stock on January 2, 2021',
		image: 'item-2.jpg',
		price: 49.88,
		category: 'electronics',
		description: 'Up to 8 hours of battery life for when you’re on the go',
	},
	{
		id: 62,
		title: "De'Longhi La Specialista Espresso Machine with Knock Box",
		stock: 'Only 1 left in stock - order soon.',
		image: 'item-3.jpg',
		price: 745.05,
		category: 'electronics',
		description:
			'RESHEST COFFEE, EVERY TIME: Integrated grinder with Patent Pending Technology delivers consistent grinding and optimal coffee dose.',
	},
	{
		id: 63,
		title: 'Oculus Quest 2 — Advanced All-In-One Virtual Reality Headset',
		stock: 'In stock on April 2, 2021',
		image: 'item-4.jpg',
		price: 399.0,
		category: 'electronics',
		description:
			'Experience our most advanced all-in-one VR system yet with Oculus Quest 2',
	},
	{
		id: 64,
		title: 'All-new Echo Dot (4th Gen) | Glacier White',
		stock: 'In stock on December 25, 2020',
		image: 'item-5.jpg',
		price: 39.99,
		category: 'electronics',
		description:
			'Smart speaker with Alexa | Glacier White · Meet the all-new Echo Dot ',
	},
	{
		id: 65,
		title: 'Samsung Galaxy Note 10, 256GB',
		stock: 'Only 11 left in stock - order soon.',
		image: 'item-6.jpg',
		price: 569.0,
		category: 'electronics',
		description:
			'Accessories sold separately. Model and color availability may vary by country or carrier',
	},
	{
		id: 66,
		title: 'Nulaxy A5 Tablet Stand- Heavy Duty Black',
		stock: 'In stock on February 7, 2021',
		image: 'item-7.jpg',
		price: 569.0,
		category: 'tablet Samsung',
		description:
			'Tablet Stand Ergonomic Foldable Portable Laptop Stand for Mac MacBook PRO Air, Lenovo, HP, 10-15',
	},
	{
		id: 66,
		title: 'Lenovo Flex 5 14 2-in-1 Laptop, 14.0',
		stock: 'In stock on May 8, 2021',
		image: 'item-8.jpg',
		price: 645.0,
		category: 'computers & Laptops',
		description:
			'Lenovo Flex 5 14 2-in-1 Laptop, 14.0 FHD (1920 x 1080) Touch Display, AMD Ryzen 5 4500U Processor, 16GB DDR4, 256GB SSD, AMD Radeon Graphics, Digital Pen Included, Win 10, 81X20005US, Graphite Grey',
	},
	{
		id: 49.99,
		title: 'All-new Echo  | Smart speaker with Alexa',
		stock: 'Only 3 left in stock - order soon.',
		image: 'item-1-1_.jpg',
		price: 676.0,
		category: 'electronics ',
		description:
			' connects to Alexa, a cloud-based voice service, to play music, set timers and alarms, control compatible smart home devices, and more',
	},
	{
		id: 67,
		title: 'The First Years Stack Up Cup Toys ',
		stock: 'Visit the The First Years Store',
		image: 'item-9.jpg',
		price: 676.0,
		category: 'Toys ',
		description:
			'brightly colored cups stack together with Unique Holes in the Bottom for water play ',
	},
]

const itemsFixPrice = items.map((item) => item.price)

export { itemsFixPrice }

export default items
