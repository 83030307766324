import { useState, useCallback, ReactNode } from "react";
import { Toast, ToastConfig } from "./types";
import { ToastContext } from "./context";
import { ToastContainer } from "./ToastContainer";

interface ToastProviderProps {
  children: ReactNode;
  config?: Partial<ToastConfig>;
}

export const ToastProvider = ({
  children,
  config = {},
}: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const defaultConfig: ToastConfig = {
    position: "top-right",
    defaultDuration: 3000,
    ...config,
  };

  const addToast = useCallback(
    ({ message, type, duration }: Omit<Toast, "id">) => {
      const id = Math.random().toString(36).substr(2, 9);
      const newToast: Toast = {
        id,
        message,
        type,
        duration: duration || defaultConfig.defaultDuration,
      };
      setToasts((prev) => [...prev, newToast]);
    },
    [defaultConfig.defaultDuration]
  );

  const removeToast = useCallback((id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer
        position={defaultConfig.position}
        toasts={toasts}
        onRemove={removeToast}
      />
    </ToastContext.Provider>
  );
};
