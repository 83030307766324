//  function to truncate(cut) the string if the length of given string

import { v4 as uuidv4 } from "uuid";
import { OobAuthorizationApiPayloadType } from "../../api/types";
import { OTP_PIN } from "../../constants/constants";

//  bigger than  given number(n)
export function truncate(string: string, n: number) {
  return string.length > n ? `${string.substr(0, n - 1)}....` : string;
}

export function getRandomIntNumberBetween(min = 1, max = 10) {
  // min: 5, max: 10
  return Math.floor(Math.random() * (max - min + 1) + min); // 10.999999999999 => 10
}

export const constructPayload = (
  card: any,
  basket: object[],
  setPaymentDataToState: (paymentData: any) => void,
  charges: number
): OobAuthorizationApiPayloadType => {
  const currentDate = new Date();
  const isoDateString = currentDate.toISOString();

  const paymentData = {
    transaction: {
      authenticationId: uuidv4(),
      amount:
        basket.map((item: any) => item.price).reduce((acc, cv) => acc + cv) +
        charges,
      currency: "AED",
      dateTime: isoDateString,
    },
    merchant: {
      name: "amazon",
      country: "UAE",
      merchantId: `merchant-1235`,
      acquirerBin: `bin-1234`,
    },
    card: {
      panMasked: `xxxx-xxxx-xxxx-${card.cardNo.slice(15, 19)}`,
      cardId: card.id,
      paymentScheme: card.paymentScheme,
      accountId: card.id, // cardid
      cardProductId: "prod123",
      institutionId: "inst123",
      cardNo: card.cardNo,
      bank: card.bank,
      bankLogo: card.bankLogo,
      bankAppName: card.bankAppName,
    },
    customer: {
      customerId: card.customer.id,
      firstName: card.customer.firstName,
      lastName: card.customer.last_name,
      email: card.customer.customerId,
    },
  };
  setPaymentDataToState(paymentData);
  return paymentData;
};

export const otpPinValidation = (pin: string) => {
  if (pin === OTP_PIN) {
    return true;
  } else {
    return false;
  }
};

export const formatCurrency = (amount: number, currency: string): string => {
  return new Intl.NumberFormat("en-AE", { style: "currency", currency }).format(
    amount
  );
};
