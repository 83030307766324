import React from 'react';
import { logoApi } from '../../constants/constants';

interface LogoProps {
    domain: string;    
    size?: number;
}

const Logo: React.FC<LogoProps> = (props) => {
    const {domain, size} = props;
  return <img src={`${logoApi.url}${domain}?token=${logoApi.token}&size=${size??"50"}`} alt={domain} />;
};

export default Logo;