import { useEffect, useState } from "react";
import { X } from "lucide-react";
import { Toast } from "./types";
import { ToastIconFactory } from "./ToastIconFactory";
import { STYLE_VARIANTS } from "./constants";

interface ToastItemProps {
  toast: Toast;
  onClose: () => void;
}

export const ToastItem = ({ toast, onClose }: ToastItemProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    const timer = setTimeout(() => {
      setIsExiting(true);
      onClose();
    }, toast.duration);

    return () => clearTimeout(timer);
  }, [toast.duration, onClose]);

  return (
    <div
      className={`flex items-center gap-3 px-4 py-3 rounded-lg border ${
        STYLE_VARIANTS[toast.type]
      } ${
        isVisible ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
      } ${isExiting ? "translate-x-full opacity-0" : ""}`}
      role="alert"
      aria-live="polite"
    >
      {ToastIconFactory[toast.type]()}
      <p className="text-gray-700">{toast.message}</p>
      <button
        onClick={onClose}
        className="ml-4 text-gray-400 hover:text-gray-600 transition-colors"
        aria-label="Close notification"
      >
        <X className="w-4 h-4" />
      </button>
    </div>
  );
};
