import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
  Method,
} from "axios";

// Interfaces
export interface IHttpClient {
  request<T>(config: AxiosRequestConfig): Promise<T>;
}

export interface IRetryStrategy {
  shouldRetry(error: AxiosError): boolean;
  retryDelay: number;
}

interface IHeaderManager {
  getHeaders(): Record<string, string>;
  setHeader(key: string, value: string): void;
  removeHeader(key: string): void;
  clearHeaders(): void;
}

// Implementations
class AxiosHttpClient implements IHttpClient {
  private axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({ baseURL });
  }

  async request<T>(config: AxiosRequestConfig): Promise<T> {
    const response = await this.axiosInstance.request<T>(config);
    return response.data;
  }
}

class SimpleRetryStrategy implements IRetryStrategy {
  constructor(public maxRetries: number, public retryDelay: number) {}

  shouldRetry(error: AxiosError): boolean {
    const retryCount = (error.config as any)._retryCount || 0;
    return retryCount < this.maxRetries;
  }
}

class HeaderManager implements IHeaderManager {
  private headers: Record<string, string> = {};

  getHeaders(): Record<string, string> {
    return { ...this.headers };
  }

  setHeader(key: string, value: string): void {
    this.headers[key] = value;
  }

  removeHeader(key: string): void {
    delete this.headers[key];
  }

  clearHeaders(): void {
    this.headers = {};
  }
}

// Main ApiInterceptor class
class ApiInterceptor {
  private httpClient: IHttpClient;
  private retryStrategy: IRetryStrategy;
  private headerManager: IHeaderManager;

  constructor(
    baseURL: string,
    retryStrategy: IRetryStrategy = new SimpleRetryStrategy(0, 1000),
    headerManager: IHeaderManager = new HeaderManager()
  ) {
    this.httpClient = new AxiosHttpClient(baseURL);
    this.retryStrategy = retryStrategy;
    this.headerManager = headerManager;
  }

  private async executeWithRetry<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      return await this.httpClient.request<T>(config);
    } catch (error) {
      if (axios.isAxiosError(error) && this.retryStrategy.shouldRetry(error)) {
        (config as any)._retryCount = ((config as any)._retryCount || 0) + 1;
        await new Promise((resolve) =>
          setTimeout(resolve, this.retryStrategy.retryDelay)
        );
        return this.executeWithRetry<T>(config);
      }
      throw error;
    }
  }

  private createConfig(
    method: Method,
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...config,
      method,
      url,
      data,
      headers: { ...this.headerManager.getHeaders(), ...config?.headers },
    };
  }

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.executeWithRetry<T>(
      this.createConfig("get", url, null, config)
    );
  }

  async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.executeWithRetry<T>(
      this.createConfig("post", url, data, config)
    );
  }

  async put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.executeWithRetry<T>(
      this.createConfig("put", url, data, config)
    );
  }

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.executeWithRetry<T>(
      this.createConfig("delete", url, null, config)
    );
  }

  setHeader(key: string, value: string): void {
    this.headerManager.setHeader(key, value);
  }

  removeHeader(key: string): void {
    this.headerManager.removeHeader(key);
  }

  clearHeaders(): void {
    this.headerManager.clearHeaders();
  }
}

export default ApiInterceptor;
