// function to fetch fakestoreapi
const request = {
  fetchAll: `/products`,
  fetchWomenClothing: `/products/category/women's clothing`,
  fetchMenClothing: `/products/category/men's clothing`,
  fetchJewelery: `/products/category/jewelery`,
  fetchElectronics: `/products/category/electronics`,
};

export const acsRequests = {
  getOobTransaction: (authenticationId: string) =>
    `/st-mock-backend/v1/acs/oob-authorize/${authenticationId}`,
  initiateOobAuthorization: `/st-mock-backend/v1/acs/oob-authorize`,
  updateAuthorizationResult: (authenticationId: string) =>
    `/st-mock-backend/v1/acs/oob-authorize/${authenticationId}/authorization-result`,
  getUserCards:(userId:string)=> `/st-mock-backend/v1/oob-cards?search=${userId}`
};

export default request;
