// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import {
  updateProfile,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import { getFirestore } from "firebase/firestore";
const googleProvider = new GoogleAuthProvider();

const firebaseConfig = {
  apiKey: "AIzaSyAAB9r06ncB_2rIgO-3Q4nn2RD8Xg9GP5w",

  authDomain: "digibank-ecommerce.firebaseapp.com",

  projectId: "digibank-ecommerce",

  storageBucket: "digibank-ecommerce.appspot.com",

  messagingSenderId: "958573414571",

  appId: "1:958573414571:web:66b8ac56ba8ff3c1cc4fee",

  measurementId: "G-3XL2EN038H",
};

// Initialize Firebase
getApps().length > 0 ? getApps() : initializeApp(firebaseConfig);

const auth = getAuth();
const db = getFirestore();

export {
  auth,
  db,
  GoogleAuthProvider,
  googleProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
};
